import './App.css';
import { useEffect, useState } from 'react';
import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import About from './components/About';
import Footer from './components/Footer';
import Main from './components/Main';
import WhatFengShuiCanDo from './components/WhatFengShuiCanDo';
import WhatIsFengShui from './components/WhatIsFengShui';
import OurServices from './components/OurServices';
import OurServices2 from './components/OurServices2';

function App() {
  const [btnStyle, setBtnStyle] = useState({ display: 'none !important' })
  useEffect(() => {
    AOS.init();
  }, [])
 
    return (
      <div className="App">
        <Main />
        {/* <OurServices /> */}
        <OurServices2/>
        {/* <WhyFengShui/> */}
        <WhatIsFengShui />
        <WhatFengShuiCanDo />
        <About />
        <Footer />
      </div>
    );
  }

  export default App;
