import React, { useState } from 'react';
import logo from '../images/logo.png';
import emailjs from 'emailjs-com';
import Alert from "react-bootstrap/Alert";
import loader from '../images/loader.gif'

export default function Main() {

    const [showAlert, setShowAlert] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    function sendEmail(e) {
        e.preventDefault();
        setShowLoader(true)    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_2bkqcwp', 'template_6g6l7qu', e.target, 'k1NXLOsKWxLmUFYLd')
            .then((result) => {
                setShowLoader(false)
                setShowAlert(true)
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
            }, (error) => {
                console.log(error.text);
            });
    }


    return (
        <div>
            <div className="container-fluid m-0">
                <div className="row">
                    <div className="col-lg-6 img p-4 p-lg-5">
                        <div className="row d-flex justify-content-end">
                            <div className="col-auto">
                                <img src={logo} alt="" className="img-fluid animate_animated animate__fadeIn" width='100' />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 main-text py-5 px-4 px-md-5 p-lg-5">
                        <h2 className="display-6 fw-bold mx-3 mx-lg-5 mt-0 mt-md-4 pt-lg-5 pt-0 mt-lg-4 mb-0 animate__animated animate__fadeInDown">HARMONIZE YOUR SPACE</h2>
                        <p className="lead fw-bold mx-3 mx-lg-5 col-10 col-lg-6 animate__animated animate__fadeInUp">Bring balance and Positive Energy with Feng-Shui Mastery</p>
                        <p className='mx-3 mx-lg-5 mt-4 mb-5 col-10 col-lg-8 animate__animated animate__fadeInUp'>At our Feng-Shui Hub, embark on a transformative journey to revitalize your surroundings and enrich your life.</p>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-start mx-3 mx-lg-5 mb-md-4 mb-lg-5">
                            <a href='#what' type="button" className="btn primary-btn btn-lg px-4 me-md-2">Learn More</a>
                            <button type="button" className="btn secondary-btn btn-lg px-4" data-bs-toggle="modal" data-bs-target="#exampleModal">Contact now</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Modal --> */}
            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog">
                    <div className="modal-content px-3" style={{ backgroundColor: '#c3aa7d' }}>
                        <div className="title d-flex justify-content-between mt-4 px-3 pb-2" style={{ borderBottom: '1px solid rgba(0, 0, 0, .3)' }}>
                            <h1 className="modal-title fs-5 fw-bold" id="exampleModalLabel">Contact Us</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ border: 'none !important' }}>
                        {showLoader && (
                                <div className="d-flex align-items-center justify-content-center">
                                    <img src={loader} width='150' alt="" className="img-fluid" />
                                </div>
                            )}
                            {showAlert && (
                                <Alert variant="success" dismissible>
                                    <Alert.Heading>Thank you for your Interest!</Alert.Heading>
                                    <p>
                                       We will make every effort to respond promptly and comprehensively. Your query is important to us, and we're eager to assist you in any way we can.
                                    </p>
                                </Alert>
                            )}
                            <form className='pt-4 pb-5' onSubmit={sendEmail}>
                                <div class="mb-3">
                                    <label htmlFor="exampleFormControlInput1" class="form-label">Name</label>
                                    <input type="text" class="form-control shadow-none" id="exampleFormControlInput1" placeholder="Enter your Full name.." name="from_name" />
                                </div>
                                <div class="mb-3">
                                    <label htmlFor="exampleFormControlInput1" class="form-label">Email address</label>
                                    <input type="email" class="form-control shadow-none" id="exampleFormControlInput1" placeholder="Enter your e-mail address.." name="from_email" />
                                </div>
                                <div class="mb-3">
                                    <label htmlFor="exampleFormControlInput1" class="form-label">Contact Number</label>
                                    <input type="tel" class="form-control shadow-none" id="exampleFormControlInput1" placeholder="Enter your Contact Number.." name="contact_number" />
                                </div>
                                <div class="mb-3">
                                    <label htmlFor="exampleFormControlTextarea1" class="form-label">Message</label>
                                    <textarea class="form-control shadow-none" id="exampleFormControlTextarea1" rows="4" placeholder='Enter your message here..' name="message"></textarea>
                                </div>
                                <button type="submit" className="btn-lg btn primary-btn col-12 mt-3">Send</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
