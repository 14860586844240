import React, { useState } from 'react';
import logo from '../images/logo.png';
import img from '../images/feng-shui.png'


export default function Main() {


    return (
        <div>
            <div className="container-fluid m-0">
                <div className="row">
                    <div className="col-lg-6 svc-img p-4 p-lg-5 order-md-1">
                        <div className="row d-flex justify-content-end">
                            <div className="col-auto">
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 svc-text py-5 px-4 px-md-5 p-lg-5">
                    <h2 className="display-6 fw-bold mx-3 mx-lg-5 mt-0 pt-lg-5 pt-0 mt-lg-0 mb-0 animate__animated animate__fadeInDown">OUR SERVICES</h2>
                        <div className="row mx-2 mx-lg-5 mt-4 mb-3">
                            <div className="col-2 col-md-auto">
                                <img src={img} width='36' className="img-fluid" />
                            </div>
                            <div className="col-10">
                                <p className="lead fw-bold p-0 m-0">Space Analysis and Assessment</p>
                                <p>We'll assess the balance of elements, color schemes, and the overall energy flow to determine the best course of action. </p>
                            </div>
                        </div>
                        <div className="row mx-2 mx-lg-5 my-3">
                            <div className="col-2 col-md-auto">
                                <img src={img} width='36' className="img-fluid" />
                            </div>
                            <div className="col-10">
                                <p className="lead fw-bold p-0 m-0">Office and Business Feng Shui</p>
                                <p>Maximize productivity, creativity, and success in your workplace with our business-focused Feng Shui consultations. </p>
                            </div>
                        </div>
                        <div className="row mx-2 mx-lg-5 my-3" data-aos="fade-up">
                            <div className="col-2 col-md-auto">
                                <img src={img} width='36' className="img-fluid" />
                            </div>
                            <div className="col-10">
                                <p className="lead fw-bold p-0 m-0"> Feng Shui Interior Design</p>
                                <p>Combine aesthetics with Feng Shui principles for a harmonious and beautiful living space.  </p>
                            </div>
                        </div>
                        <div className="row mx-2 mx-lg-5 my-3" data-aos="fade-up">
                            <div className="col-2 col-md-auto">
                                <img src={img} width='36' className="img-fluid" />
                            </div>
                            <div className="col-10">
                                <p className="lead fw-bold p-0 m-0">Feng Shui Pools & Water elements</p>
                                <p>Unleash the Dynamic Force of Water </p>
                            </div>
                        </div>

                        <div className="row mx-2 mx-lg-5 my-3" data-aos="fade-up">
                            <div className="col-2 col-md-auto">
                                <img src={img} width='36' className="img-fluid" />
                            </div>
                            <div className="col-10">
                                <p className="lead fw-bold p-0 m-0">Transformation and Renovation</p>
                                <p>Begin Anew with Positive Reorientation.</p>
                            </div>
                        </div>

                        <div className="row mx-2 mx-lg-5 my-3" data-aos="fade-up">
                            <div className="col-2 col-md-auto">
                                <img src={img} width='36' className="img-fluid" />
                            </div>
                            <div className="col-10">
                                <p className="lead fw-bold p-0 m-0">Remote Feng Shui Consultations</p>
                                <p>No matter where you are in the world, you can benefit from our expertise through remote consultations.</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

        </div>
    )
}
